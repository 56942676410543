@font-face {
  font-family: "Brandon";
  src: url("./fonts/BrandonGrotesque-Regular.woff2") format("woff2"),
    url("./fonts/BrandonGrotesque-Regular.woff") format("woff");
}
@font-face {
  font-family: "BrandonMedium";
  src: url("./fonts/BrandonGrotesque-Medium.woff") format("woff2"),
    url("./fonts/BrandonGrotesque-Medium.woff") format("woff");
}
@font-face {
  font-family: "BrandonBold";
  src: url("./fonts/BrandonGrotesque-Bold.woff2") format("woff2"),
    url("./fonts/BrandonGrotesque-Bold.woff") format("woff");
}

/* a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
} */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}

button,
img,
svg {
  user-select: none;
}

button {
  cursor: pointer;
}

button:hover {
  opacity: .5;
}

input, 
button {
  outline: none;
}

a {
  text-decoration: none;
}

#root {
  overflow: hidden;
}

.react-tiny-popover-container {
  z-index: 20;
}

.custom-scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: none;
	border-radius: 20px;
	background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar
{
  width: 14px;
	border-radius: 20px;
	background-color: #f8f9f9;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
  border-radius: 20px;
  height: 32px;
	-webkit-box-shadow: none;
	background-color: #368B97;
}

canvas:focus {
  outline: none;
}

.mapboxgl-popup {
  z-index: 3;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  border-radius: 20px;
  font-family: 'Brandon',sans-serif;
  font-size: 16px;
  line-height: 1.5;
  padding: 6px 12px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  margin-bottom: 15px;
}

.mapboxgl-popup-anchor-left {
  margin-left: 0;
  margin-top: -15px;
}

.mapboxgl-popup-anchor-right {
  padding-right: 0;
  margin-top: -15px;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  margin-left: -1px;
}

.mapboxgl-popup-anchor-top-left {
  margin-top: -20px;
  margin-left: -25px;
}

.mapboxgl-popup-anchor-top-right {
  margin-top: -20px;
  margin-left: 25px;
}

.mapboxgl-popup-anchor-top {
  margin-top: -30px;
}

.mapboxgl-popup-anchor-bottom-left {
  margin-left: -25px;
  margin-top: 5px;
}
.mapboxgl-popup-anchor-bottom-right {
  margin-left: 25px;
  margin-top: 5px;
}

.mapboxgl-popup-anchor-bottom {
  margin-left: 0;
  margin-top: 15px;
}